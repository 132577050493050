import React from "react";
import "./App.scss";
import ConstantComponent from "./component/ConstantComponent";
import CommandParser, { ResultType } from "./parser/CommandParser";
import BootView from "./view/BootView";
import CommandLineView from "./view/CommandLineView";
import InputView from "./view/InputView";

const MIN_CLICK_INTERVAL = 500;

interface Props {}

interface State {
  path: string;
  entries: any[];
}

const COMMAND_HISTORY_SIZE = 100;

export default class App extends ConstantComponent<Props, State> {
  private isDragging = false;
  private lastClickTimestamp = 0;

  private readonly commandHistory: string[] = [];
  private historyIndex = 0;

  constructor(props: Props) {
    super(props);

    App.focusInput = App.focusInput.bind(this);

    this.state = { path: "~", entries: [<BootView />] };
  }

  private static focusInput() {
    App.getInput().focus();
  }

  private static getInput() {
    return document.getElementById("terminal-input") as HTMLInputElement;
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.onPointerDown);
    window.addEventListener("touchstart", this.onPointerDown);
    window.addEventListener("mousemove", this.onPointerMove);
    window.addEventListener("touchmove", this.onPointerMove);
    window.addEventListener("mouseup", this.onPointerUp);
    window.addEventListener("touchend", this.onPointerUp);
    window.addEventListener("keydown", this.onKeyDown);
    App.focusInput();
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.onPointerDown);
    window.removeEventListener("touchstart", this.onPointerDown);
    window.removeEventListener("mousemove", this.onPointerMove);
    window.removeEventListener("touchmove", this.onPointerMove);
    window.removeEventListener("mouseup", this.onPointerUp);
    window.removeEventListener("touchend", this.onPointerUp);
    window.removeEventListener("keydown", this.onKeyDown);
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    return newState.entries.length !== this.state.entries.length;
  }

  render() {
    return (
      <div id="app">
        <div id="terminal-window">
          {this.state.entries.map((command, i) => (
            <div key={i}>{command}</div>
          ))}
          <CommandLineView path={this.state.path} flex>
            <InputView />
          </CommandLineView>
        </div>
      </div>
    );
  }

  private readonly onPointerDown = () => {
    this.isDragging = false;
  };

  private readonly onPointerMove = () => {
    this.isDragging = true;
  };

  private readonly onPointerUp = (e: MouseEvent | TouchEvent) => {
    if (e instanceof MouseEvent && e.which !== 1) {
      return;
    }

    if (!this.isDragging) {
      const now = Date.now();
      if (now >= this.lastClickTimestamp + MIN_CLICK_INTERVAL) {
        App.focusInput();
      }
      this.lastClickTimestamp = now;
    }
  };

  private readonly onKeyDown = (e: KeyboardEvent) => {
    if (!e.ctrlKey) {
      App.focusInput();
    }

    switch (e.key) {
      case "Enter": {
        this.submitCommand();
        break;
      }
      case "ArrowDown": {
        e.preventDefault()
        this.readHistoryEntry(this.historyIndex - 1);
        break;
      }
      case "ArrowUp": {
        e.preventDefault()
        this.readHistoryEntry(this.historyIndex + 1);
        break;
      }
    }
  };

  private submitCommand() {
    const input = App.getInput();

    this.pushCommandToHistory(input.value);
    this.historyIndex = 0;

    const result = CommandParser.parse(input.value, this.state.path);
    input.value = "";
    if (result.type === ResultType.PRINT) {
      this.state.entries.push(...result.content);
    } else {
      switch (result.type) {
        case ResultType.CLEAR: {
          this.state.entries.length = 0;
          break;
        }
        case ResultType.EXIT: {
          window.location.href = "about:blank";
          break;
        }
      }
    }
    this.forceUpdate();
  }

  private pushCommandToHistory(command: string) {
    if (command === this.commandHistory[this.commandHistory.length - 1]) {
      return;
    }

    if (this.commandHistory.length >= COMMAND_HISTORY_SIZE) {
      this.commandHistory.splice(
        0,
        this.commandHistory.length - COMMAND_HISTORY_SIZE + 1
      );
    }

    this.commandHistory.push(command);
  }

  private readHistoryEntry(index: number) {
    if (index === 0) {
      this.historyIndex = 0;
      App.getInput().value = "";
    } else if (index > 0 && index <= this.commandHistory.length) {
      this.historyIndex = index;
      App.getInput().value =
        this.commandHistory[this.commandHistory.length - this.historyIndex];
    }
  }
}

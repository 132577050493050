import React from "react";
import ConstantComponent from "../component/ConstantComponent";
import ContactView from "./ContactView";
import { DEFAULT_USER_NAME } from "../Constants";

interface Props {
  tty?: number;
}

export default class BootView extends ConstantComponent<Props> {
  render() {
    return (
      <>
        <h1 className="tertiary">Kajetan Suchański</h1> <span>1.0.0 LTS</span>{" "}
        <h1 className="primary">Kaj</h1>
        <h2 className="primary">OS</h2> <span>tty{this.props.tty || 1}</span>
        <br />
        <br />
        <div>KajOS login: {DEFAULT_USER_NAME}</div>
        <div>Password:</div>
        <div>
          Welcome to <strong className="tertiary">Kajetan Suchański</strong>{" "}
          1.0.0 LTS
        </div>
        <br />
        <ContactView />
        <br />
        <span>Type `help` to see the list of all commands.</span>
      </>
    );
  }
}

import Program from "../Program";
import Command from "../Command";
import SimpleCommand from "../SimpleCommand";

type ThesisInfo = {
  titlePl: string;
  titleEn: string;
  year: string;
};

export default class EduThesisProgram extends Program {
  name = "thesis";
  description = "Display theses information";
  usageArguments = ["edu", "thesis"];

  private static getLsCommand() {
    return new SimpleCommand("ls", "List theses", () => {
      const rows = this.getTheses();
      rows.unshift({
        titlePl: "POLISH TITLE",
        titleEn: "ENGLISH TITLE",
        year: "YEAR",
      });
      return rows.map((row, index) => this.createThesisRow(index, row));
    });
  }

  private static createThesisRow(key: number, thesis: ThesisInfo) {
    return (
      <div key={key} className="flex">
        <div style={{ flexGrow: 0.1 }}>{thesis.year}</div>
        <div className="grow">{thesis.titlePl}</div>
        <div className="grow">{thesis.titleEn}</div>
      </div>
    );
  }

  private static getTheses(): ThesisInfo[] {
    return [
      {
        titlePl:
          "Analiza efektywności autorskiego języka skryptowego do sterowania wirtualnymi robotami z zastosowaniem algorytmów ewolucyjnych",
        titleEn:
          "Efficiency analysis of the original scripting language for controlling virtual robots with the use of evolutionary algorithms",
        year: "2021",
      },
      {
        titlePl:
          "Algorytmy kryptograficzne i ich implementacja na urządzeniach mobilnych",
        titleEn:
          "Cryptographic algorithms and their implementation on mobile devices",
        year: "2020",
      },
    ];
  }

  getCommands(): Command[] {
    return [EduThesisProgram.getLsCommand()];
  }
}

import Program from "../Program";
import EduThesisProgram from "./EduThesisProgram";

export default class EduProgram extends Program {
  name = "edu";
  usageArguments = ["edu"];
  description = "The documents related to education";

  getCommands() {
    return [new EduThesisProgram()];
  }
}

import ConstantComponent from "../component/ConstantComponent";
import { DEFAULT_USER_NAME } from "../Constants";

interface Props {
  path: string;
  user?: string;
  root?: boolean;
  flex?: boolean;
}

export default class CommandLineView extends ConstantComponent<Props> {
  render() {
    return (
      <div className={this.props.flex ? "flex" : ""}>
        <span className="bold primary">
          {this.props.user || DEFAULT_USER_NAME}@KajOS
        </span>
        <span>:</span>
        <span className="secondary">{this.props.path}</span>
        <span className="padding-right-char">
          {this.props.root ? "#" : "$"}
        </span>
        {this.props.children}
      </div>
    );
  }
}

import React from "react";
import CommandLineView from "../view/CommandLineView";
import ContactView from "../view/ContactView";
import HelpView from "../view/HelpView";
import TextView from "../view/TextView";
import EduProgram from "./edu/EduProgram";

export enum ResultType {
  PRINT,
  CLEAR,
  EXIT,
}

export type ParserResult = {
  type: ResultType;
  content?: any;
};

// TODO neofetch
// TODO '<commandName> accepts no arguments'
export default class CommandParser {
  private static readonly commands: { [id: string]: any } = {
    clear: () => ResultType.CLEAR,
    contact: () => <ContactView />,
    echo: (args: string[]) => (
      <TextView>
        {args.map((arg) => arg.replaceAll(/["'`]/g, "")).join(" ")}
      </TextView>
    ),
    edu: (args: string[]) => new EduProgram().run(args),
    exit: () => ResultType.EXIT,
    help: () => <HelpView />,
    whoami: () => <TextView>Where am I going?</TextView>,
  };

  static parse(input: string, path: string): ParserResult {
    const typedCommand = (
      <CommandLineView path={path}>
        <span>{input}</span>
      </CommandLineView>
    );

    input = input.trim();
    let parsed: any;

    if (input !== "") {
      const parts = input.split(" ");
      const commandName = parts[0];

      if (this.isAttackAttempt(commandName)) {
        parsed = <TextView>Nice try, kid.</TextView>;
      } else {
        const commandResult = this.commands[commandName];
        if (commandResult) {
          parsed = commandResult(parts.splice(1));
          if (typeof parsed === "number") {
            return { type: parsed };
          }
        } else {
          parsed = <TextView>ksh: {commandName}: command not found</TextView>;
        }
      }
    }

    const result: any[] = [typedCommand];
    if (parsed) {
      result.push(parsed);
    }

    return { type: ResultType.PRINT, content: result };
  }

  private static isAttackAttempt(commandName: string) {
    const attackPrefixes = [
      "<script",
      "%3cscript",
      "<style",
      "%3cstyle",
      "<img",
      "%3cimg",
    ];
    return attackPrefixes.some((prefix) =>
      commandName.toLowerCase().startsWith(prefix)
    );
  }
}

import Command from "./Command";

export default class SimpleCommand extends Command {
  constructor(
    public name: string,
    public description: string,
    public run: (args: string[]) => any
  ) {
    super();
  }
}

import ConstantComponent from "../component/ConstantComponent";

type LinkInfo = { name: string; url: string };

export default class ContactView extends ConstantComponent {
  private static createCommandColumn(
    links: LinkInfo[],
    start: number = 0,
    end: number = links.length - 1
  ) {
    const col: any[] = [];
    for (let i = start; i <= end; ++i) {
      const link = links[i];
      col.push(
        <div key={i}>
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.name}
          </a>
        </div>
      );
    }

    return col;
  }

  private static getLinks(): LinkInfo[] {
    return [
      { name: "Em@il", url: "mailto:hi@kajetansuchanski.pl" },
      { name: "GitHub", url: "https://github.com/kajetan-suchanski" },
      {
        name: "Google Play",
        url: "https://play.google.com/store/apps/dev?id=8940225843692990123",
      },
      { name: "Kwezal", url: "https://kwezal.com/en/team/mastermind" },
      { name: "LinkedIn", url: "https://pl.linkedin.com/in/kajetansuchanski" },
      { name: "Medium", url: "https://medium.com/@KajetanSuchanski" },
      { name: "Twitter", url: "https://twitter.com/kaj_suchanski" },
    ];
  }

  render() {
    const commands = ContactView.getLinks();
    const half = Math.ceil(commands.length / 2);

    return (
      <div className="flex">
        <div className="grow">
          {ContactView.createCommandColumn(commands, 0, half - 1)}
        </div>
        <div className="grow">
          {ContactView.createCommandColumn(commands, half)}
        </div>
      </div>
    );
  }
}

import ConstantComponent from "../component/ConstantComponent";

export default class HelpView extends ConstantComponent {
  private static createCommandColumn(
    commands: string[],
    start: number = 0,
    end: number = commands.length - 1
  ) {
    const col: any[] = [];
    for (let i = start; i <= end; ++i) {
      col.push(
        <div key={i}>
          <span>{commands[i]}</span>
        </div>
      );
    }

    return col;
  }

  private static getCommands() {
    return ["contact", "echo", "edu", "exit", "help", "whoami"];
  }

  render() {
    const commands = HelpView.getCommands();
    const half = Math.ceil(commands.length / 2);

    return (
      <>
        <div>KajOS ksh, version 1.0.0</div>
        <div>
          These shell commands are defined internally. Type `help` to see this
          list.
        </div>
        <br />
        <div className="flex">
          <div className="grow">
            {HelpView.createCommandColumn(commands, 0, half - 1)}
          </div>
          <div className="grow">
            {HelpView.createCommandColumn(commands, half)}
          </div>
        </div>
      </>
    );
  }
}

import CommandUsageView from "../view/CommandUsageView";
import Command from "./Command";

export default abstract class Program extends Command {
  abstract usageArguments: string[];

  abstract getCommands(): Command[];

  run(args: string[]) {
    if (args.length === 0 || (args[0] === "help")) {
      return (
        <CommandUsageView
          usageArguments={this.usageArguments}
          description={this.description}
          commands={this.getCommands()}
        />
      );
    }

    const commandName = args.shift();
    const command = this.getCommands().find((cmd) => cmd.name === commandName);
    if (command !== undefined) {
      return command.run(args);
    } else {
      const mainProgramName = this.usageArguments[0];
      const commandChain = this.usageArguments.slice(1);
      return (
        <>
          <div>
            {mainProgramName}: '{commandChain.join(" ")} {commandName}' command
            not found.
          </div>
          <div>See '{this.usageArguments.join(" ")} help'</div>
        </>
      );
    }
  }
}

import ConstantComponent from "../component/ConstantComponent";
import Command from "../parser/Command";

interface Props {
  usageArguments: string[];
  description: string;
  commands: Command[];
}

export default class CommandUsageView extends ConstantComponent<Props> {
  render() {
    return (
      <>
        <div>Usage: {this.props.usageArguments.join(" ")} COMMAND</div>
        <br />
        <div>{this.props.description}</div>
        <br />
        <div>Commands:</div>
        <div className="flex">
          {this.props.commands.map((command) => (
            <div key={command.name}>
              <div className="grow padding-left-tab">{command.name}</div>
              <div className="grow">{command.description}</div>
              <div className="grow"></div>
              <div className="grow"></div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
